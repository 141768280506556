canvas.visualizer {
    position: relative;
    width: 100%;
    height: 100%;
    width: 100%;
    height: 25%;
    position: fixed;
    bottom: 0;
    left: 80px;
    background-color: rgba($color: #000000, $alpha: .15);
    filter: blur(16px);
}