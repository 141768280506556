.app-fuulscreen-loader-container {
  align-items: center;
  background-color: none;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  z-index: 999999;
  transition: visibility 1s linear;
  visibility: visible;
}

.app-fuulscreen-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffac41,
      1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      2.5em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      1.75em 1.75em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      0em 2.5em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em 1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -2.6em 0em 0 0em rgba($color: #ffac41, $alpha: 0.5),
      -1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.3);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($color: #ffac41, $alpha: 0.3),
      1.8em -1.8em 0 0em #ffac41,
      2.5em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      1.75em 1.75em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      0em 2.5em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em 1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -2.6em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($color: #ffac41, $alpha: 0.5),
      1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.3),
      2.5em 0em 0 0em #ffac41,
      1.75em 1.75em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      0em 2.5em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em 1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -2.6em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($color: #ffac41, $alpha: 0.8),
      1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.5),
      2.5em 0em 0 0em rgba($color: #ffac41, $alpha: 0.3),
      1.75em 1.75em 0 0em #ffac41,
      0em 2.5em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em 1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -2.6em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($color: #ffac41, $alpha: 0.8),
      1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      2.5em 0em 0 0em rgba($color: #ffac41, $alpha: 0.5),
      1.75em 1.75em 0 0em rgba($color: #ffac41, $alpha: 0.3),
      0em 2.5em 0 0em #ffac41,
      -1.8em 1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -2.6em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($color: #ffac41, $alpha: 0.8),
      1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      2.5em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      1.75em 1.75em 0 0em rgba($color: #ffac41, $alpha: 0.5),
      0em 2.5em 0 0em rgba($color: #ffac41, $alpha: 0.3),
      -1.8em 1.8em 0 0em #ffac41,
      -2.6em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($color: #ffac41, $alpha: 0.8),
      1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      2.5em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      1.75em 1.75em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      0em 2.5em 0 0em rgba($color: #ffac41, $alpha: 0.5),
      -1.8em 1.8em 0 0em rgba($color: #ffac41, $alpha: 0.3),
      -2.6em 0em 0 0em #ffac41,
      -1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($color: #ffac41, $alpha: 0.8),
      1.8em -1.8em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      2.5em 0em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      1.75em 1.75em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      0em 2.5em 0 0em rgba($color: #ffac41, $alpha: 0.8),
      -1.8em 1.8em 0 0em rgba($color: #ffac41, $alpha: 0.5),
      -2.6em 0em 0 0em rgba($color: #ffac41, $alpha: 0.3),
      -1.8em -1.8em 0 0em #ffac41;
  }
}
