@import 'variables.scss';

@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #a8a7a7;
  -webkit-box-shadow: 0 0 0px 1000px rgba($color: #fff, $alpha: .2) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.flat-button {
  background-color: transparent;
  color: $accent-color;
  letter-spacing: 4px;
  font-family: sans-serif;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid $accent-color;
  animation: fadeIn 1s 1.8s backwards;
  cursor: pointer;
  // white-space: nowrap;

  &:hover {
    background: $accent-color;
    color: #000;
  }
}

#clickable {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10);
  }
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  /* To make it round */
  background-color: rgba(0, 0, 0, 0.3);

  width: 100px;
  height: 100px;
  margin-top: -50px;
  /* for positioning */
  margin-left: -50px;

  animation: ripple 1s;
  opacity: 0;
  z-index: 999;
}