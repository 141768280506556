@import '../../variables.scss';

.App {
  position: relative;
}

.page {
  flex: 1;
  padding-top: 16px;
  padding-left: 30px;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.c {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tags {
  color: $accent-color;
  opacity: 0.6;
  margin-left: 30px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
  z-index: 1;
  display: block;
}

.bottom-tags {
  margin-top: auto;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  min-height: 90vh;
  padding-left: 90px;
  opacity: 0;
  background-color: none;
  margin: 0 auto;
  padding-top: 8%;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
}

.home-page,
.contact-page,
.about-page {
  .text-zone {
    width: 35%;
    opacity: 0;
    animation: fadeIn 1s forwards;
  }

  h1 {
    font-size: 5.3rem;
    font-family: 'Coolvetica';
    color: $accent-color;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;

    img {
      width: 5rem;
      opacity: 0;
      margin: 0;
      padding: 0;
      height: 5rem;
      animation: rotateIn 1s linear both;
      animation-delay: 0.4s;
      margin-right: -13px;
      display: inline-block;
    }

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      font-size: 18px;
      color: $accent-color;
      opacity: 0.6;
      position: absolute;
      margin-top: -20px;
      left: -10px;
      line-height: 18px;
    }

    &::after {
      content: '</h1>';
      font-family: 'La Belle Aurore';
      font-size: 18px;
      color: $accent-color;
      opacity: 0.6;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      line-height: 18px;
    }
  }

  p {
    font-size: 14px;
    color: $white;
    font-family: sans-serif;
    font-weight: 300;
    min-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 0.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.3s;
    }
  }

  .text-animate-hover {
    &:hover {
      color: $white;
    }
  }
}


@media (max-width: 768px) {
  .c {
    display: none;
  }

  .tags {
    margin-left: 16px;
  }

  .bottom-tag-html {
    margin-left: -16px;
  }

  .page {
    padding-left: 0;
    padding-top: 16px;
  }

  .container {
    padding-left: 24px;
    padding-right: 16px;
    padding-top: 15%;
  }

  .home-page,
  .contact-page,
  .about-page {
    .text-zone {
      width: 100%;
    }

    h1 {
      font-size: 4rem;

      img {
        width: 3rem;
        height: 3rem;
        margin-right: -9px;
      }
    }
  }
}