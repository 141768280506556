@import '../../variables.scss';

.music-button {
  position: fixed;
  top: 16px;
  right: 16px;

  z-index: 9999;
  padding: 2px 4px;
  background-color: rgba($color: #000, $alpha: 0.5);
  cursor: pointer;

  border: 1px solid $primary-color;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: medium;

  color: $primary-color;

  span.sound {
    font-weight: 500;
    color: white;
  }

  span.on {
    color: $accent-color;
  }
}

.music-button-active {
  border-color: $accent-color;
}
