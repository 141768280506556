@import '../../variables.scss';

.nav-bar {
  background: transparent;
  width: 60px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  // position: absolute;
  // top: 0;
  // left: 0;
  z-index: 4;
  // min-height: 500px;
}

.logo {
  display: block;
  padding: 8px 0;
  text-decoration: none;
}

img {
  display: block;
  margin: 8px auto;
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.logo-subtitle {
  color: $white;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
}

nav {
  display: block;
  text-align: center;
  width: 100%;

  a {
    font-size: 22px;
    color: $grey;
    display: block;
    line-height: 51px;
    height: 51px;
    position: relative;
    text-decoration: none;

    i {
      transition: all 0.3s ease-out;
    }

    &:hover {
      color: $accent-color;
      background-color: $black;

      svg {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      font-size: 9px;
      letter-spacing: 2px;
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      text-align: center;
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &:first-child {
      &::after {
        content: 'HOME';
      }
    }
  }

  a.about-link {
    &:after {
      content: 'ABOUT';
    }
  }

  a.contact-link {
    &:after {
      content: 'CONTACT';
    }
  }

  a.active {
    svg {
      color: $accent-color;
    }
  }
}

ul {
  padding: 0;
  list-style: none;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  display: block;
}

li {
  a {
    padding: 7px 0;
    display: block;
    font-size: 15px;
    line-height: 16px;

    &:hover svg {
      color: $accent-color;
    }
  }
}
